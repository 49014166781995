.inventory_container{

  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; */
}
.inventory_content{
  padding: 20px 0;
}
.inventory_item{

}
.title{

}