@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@700&family=Roboto&family=Source+Sans+Pro:wght@600;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p, span {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 24px;
  color: #867E76;
  padding: 0;
  margin: 0;
}
h1{
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 32px;
  text-align: center;
  color: #4B453E;
  margin: 0;
}
.app_content{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  flex-direction: row;
}
.container{
  width: 400px;
  border-radius: 15px;
  box-shadow: 0px 0px 10px rgba(75, 69, 62, 0.15);
  background-color: #F1EDE8;
}
.content{
  padding: 20px 0;
  min-height: 250px;
}
.loader{
  display: flex;
  justify-content: center;
}
.item{
  display: flex;
  justify-content: space-between;
  margin: 10px 26.5px;
  cursor: pointer;
}
.item_color{
  color: #68615B;
}
.title{
  border-bottom: 1px solid #E7DFD5;
  padding: 20px 0;
}
.total{
  border-top: 1px solid #E7DFD5;
  padding: 20px 26.5px;
  text-align: right;
}
.button_container{
  border-top: 1px solid #E7DFD5;
  display: flex;
  justify-content: center;
  padding: 20px 0;
}
.button{
  width: 287px;
  height: 54px;
  background-color: #FF7A7A;
  color: #F1EDE8;
  font-size: 24px;
  font-family:'Source Sans Pro', sans-serif;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}
.button:disabled{
  background-color: #F5CBC7;
}
.container_report{
  width: 400px;
  min-height: 0;
  border-radius: 15px;
  box-shadow: 0px 0px 10px rgba(75, 69, 62, 0.15);
  background-color: #F1EDE8;
  margin: 100px auto;
}
@media screen and (max-width: 640px) {
  .container{
    margin-bottom: 50px;
  }
}

.inventory_container{

  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; */
}
.inventory_content{
  padding: 20px 0;
}
.inventory_item{

}
.title{

}
